import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpResponse,
    HttpErrorResponse
  } from "@angular/common/http";
  import { Observable, throwError } from "rxjs";
  import { map, catchError } from "rxjs/operators";
  import { Injectable } from "@angular/core";
  import { LoadingController, Platform } from "@ionic/angular";
  import { UtilService } from "../shared/services/util.service";
  import { StorageService } from "../shared/services/storage.service";
  import { CommonService } from "../shared/services/common.service";


  @Injectable()
  export class TokenInterceptor implements HttpInterceptor {
    public ApiEnd?: Observable<boolean>;
    constructor(
      public util: UtilService,
      public storage: StorageService,
      private common: CommonService,
      public loadingController: LoadingController,
      public platform: Platform,
    ) { }

    intercept(
      request: HttpRequest<any>,
      next: HttpHandler
    ): Observable<HttpEvent<any>> {
      let token = this.storage.token;
      let user = this.storage.user;
      if (token) {
        let headers: any = {
          Authorization: "Bearer " + token,
          // "content-type": "application/json"
        };
        if (user && user._id) {
          headers["UserID"] = user._id
        }
        request = request.clone({
          setHeaders: headers,
        });
        request["isSuperAdmin"] = true;
      }

      if (!token) {
        request = request.clone({
          setHeaders: {
            // "content-type": "application/json"
          }
        });
      }



      const loader = this.loadingController.create({
        message: "wait. . ."
      });




      return next.handle(request).pipe(
        map((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {


          }

          return event;
        }),
        catchError((error: HttpErrorResponse) => {
          console.log(error,"Here");
          if (error.status === 401) {
            this.storage.removeAllProperties();
            let path = window.location.pathname;
            if (window.location.search) {
              path = path + window.location.search;
            }
            this.common.signOut();
          }
          return throwError(error);
        })
      );
    }
  }

