export class createCategory {
  name: string;
  type: string = "categoryAdmin";
  photoUrl: string;
  phoneNumber:string
  dialCode:string
  uid:string
}


// export class phoneNumberInfo {
//   phone:string = '';
// }

// export class Media{
//   _id: string = "";
//   url: string = "";
//   caption: string = "";
//   thumbnail: string = "";
//   type: string = "";
// }

// export class Address{
//   locationOfBusiness:string = '';
//   city:string = '';
//   state:string = '';
//   zipcode:string = '';
// }

// export class Timing{
//   start: string;
//   end: string;
// }

// export class DocumentReference{
//   id: string;
//   name: string;
//   photoUrl: string;
//   description: string;
// }

// export class PhoneNumber{
//   dialCode : string;
//   number : string;
// }

// export class Businesslocation {
//   type : string = "Point";
//   coordinates : number[] = [];
// }
