import { Location } from "@angular/common";

export class CreateBanner {
  title: string;
  description: string;
  media: Media = new Media();
}

export class Media{
  _id: string = "";
  url: string = "";
  caption: string = "";
  thumbnail: string = "";
  type: string = "";
}

