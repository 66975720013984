import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-header-navigation',
  templateUrl: './header-navigation.component.html',
  styleUrls: ['./header-navigation.component.css']
})
export class HeaderNavigationComponent implements OnInit {
  @Output()
  toggleSidebar = new EventEmitter<void>();
  @Input() entity : any = "account";
  @Input() keyword : any = "";

  public showSearch = false;
  user: any;
  
  constructor() { }

  ngOnInit(): void {
  }

}
