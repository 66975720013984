import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { HttpService } from '../../services/http.service';
import { UserInterfaceService } from '../../services/ui.service';

@Component({
  selector: 'app-alert-popup',
  templateUrl: './alert-popup.component.html',
  styleUrls: ['./alert-popup.component.scss'],
})
export class AlertPopupComponent implements OnInit {
  title?: string;
  message?: string;
  recievedObj?:any;
  type?: string;
  reload : boolean = false;
  constructor(
    public modalCtrl : ModalController,
    public ui : UserInterfaceService,
    public http : HttpService,
  ) { }

  ngOnInit() {}

  dismiss(){
    this.modalCtrl.dismiss();
    if(this.reload){
      window.location.reload();
    }
  }

}
