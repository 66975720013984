import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { MenuController } from '@ionic/angular';
import { StorageKeys } from '../../constants/enums';
import { CommonService } from '../../services/common.service';
import { HttpService } from '../../services/http.service';
import { RoutingService } from '../../services/routing.service';
import { StorageService } from '../../services/storage.service';
import { UtilService } from '../../services/util.service';
@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss'],
})
export class SideBarComponent implements OnInit {
  sidebarnavItems: any[] = [];
  public showMenu = "";
  public showSubmenu = "";
  @Input() menuName: string = "";

  constructor(
    public menuCtrl: MenuController,
    public routing: RoutingService,
    public http: HttpService,
    public common: CommonService,
    public store: StorageService,
    private cdr: ChangeDetectorRef,
    private util : UtilService
  ) { }

  async ngOnInit() {
    this.cdr.detectChanges();
    this.getMenuItems();
  }

  async getMenuItems() {
    this.sidebarnavItems = this.util.menu;
  }

  


  route(item: any, where: string = "showMenu") {
    if (this[where] !== item.title) {
      this[where] = item.title;
      if (item.path) {
        this.routing.route(item.path);
      }
    } else {
      this[where] = '';
    }
    if ((!item.submenu.length && where === 'showMenu') || where !== 'showMenu') {
      this.menuCtrl.close();
    }
  }

  checkOpenedLink(item: any) {
    let url = window.location.href.slice(window.location.href.lastIndexOf('/'));
    if (item.path !== '' && item.path !== null && url.includes(item.path.slice(item.path.lastIndexOf('/')))) {
      return true;
    } else {
      if (item.subMenu) {
        this.showMenu = '';
      }
      return false;
    }
  }

  addExpandClass(element: any) {
    if (element === this.showMenu) {
      this.showMenu = '0';
    } else {
      this.showMenu = element;
    }
    // this.router.navigate([`/${this.sidebarnavItems.filter(sbrItem => sbrItem.title === element)[0].path}`],{replaceUrl: true});
  }

  signout() {
    this.common.signOut();
  }

}
