import { Injectable } from '@angular/core';
import {
  ToastController,
  LoadingController,
  ModalController,
} from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class UtilService {
  menu: any[] = [
    {
      name: 'User',
      displayName: 'User',
      route: '/super-admin/users/list/User',
      icon: 'fas fa-users',
      isActive: true,
      isSelected: false,
      extraLink: false,
      class: '',
      submenu: [],
      features: [],
    },
    {
      name: 'Category',
      displayName: 'Category',
      route: '/super-admin/business',
      icon: 'fas fa-users',
      isActive: true,
      isSelected: false,
      extraLink: false,
      class: '',
      submenu: [],
      features: [],
    },
    // {
    //   name: 'Heros',
    //   displayName: 'Heros',
    //   route: '/super-admin/onboarding',
    //   icon: 'fas fa-users',
    //   isActive: true,
    //   isSelected: false,
    //   extraLink: false,
    //   class: '',
    //   submenu: [],
    //   features: [],
    // },
    // {
    //   name: 'Promo Codes',
    //   displayName: 'Promo Codes',
    //   route: '/super-admin/promo-list',
    //   icon: 'fas fa-users',
    //   isActive: true,
    //   isSelected: false,
    //   extraLink: false,
    //   class: '',
    //   submenu: [],
    //   features: [],
    // },
    // {
    //   name: 'Bookings',
    //   displayName: 'Bookings',
    //   route: '/super-admin/booking-list',
    //   icon: 'fas fa-users',
    //   isActive: true,
    //   isSelected: false,
    //   extraLink: false,
    //   class: '',
    //   submenu: [],
    //   features: [],
    // },
    // {
    //   name: 'Bids',
    //   displayName: 'Bids',
    //   route: '/super-admin/bid-list',
    //   icon: 'fas fa-users',
    //   isActive: true,
    //   isSelected: false,
    //   extraLink: false,
    //   class: '',
    //   submenu: [],
    //   features: [],
    // },
    // {
    //   name: 'Faq',
    //   displayName: 'Faq',
    //   route: '/super-admin/faq',
    //   icon: 'fas fa-users',
    //   isActive: true,
    //   isSelected: false,
    //   extraLink: false,
    //   class: '',
    //   submenu: [],
    //   features: [],
    // },
    // {
    //   name: 'Banners',
    //   displayName: 'Banners',
    //   route: '/super-admin/banner',
    //   icon: 'fas fa-users',
    //   isActive: true,
    //   isSelected: false,
    //   extraLink: false,
    //   class: '',
    //   submenu: [],
    //   features: [],
    // },
    // {
    //   name: 'Cashout Request',
    //   displayName: 'Cashout Request',
    //   route: '/super-admin/cashout-request-listing',
    //   icon: 'fas fa-users',
    //   isActive: true,
    //   isSelected: false,
    //   extraLink: false,
    //   class: '',
    //   submenu: [],
    //   features: [],
    // },
  ];

  constructor(
    public toastController: ToastController,
    public loadingController: LoadingController,
    public modalCtrl: ModalController
  ) {}

  convertToTitlecase(text: string) {
    let returnStr = '';
    for (let i = 0; i < text.length; i++) {
      if (i == 0 || text[i - 1] === ' ') {
        returnStr += text[i].toUpperCase();
      } else {
        returnStr += text[i];
      }
    }
    return (returnStr = returnStr.replace(/([A-Z])/g, ' $1').trim());
  }

  async showToastwithoutDuration(msg: any) {
    const toast = await this.toastController.create({
      message: msg,
      position: 'top',
      cssClass: 'inAppToast',
      mode: 'md',
    });
    return toast;
  }

  async showToast(msg: any, duration?: any) {
    const toast = await this.toastController.create({
      message: msg,
      position: 'top',
      cssClass: 'inAppToast',
      mode: 'md',
      duration: duration ? duration : 1000,
    });
    toast.present();
  }

  async showLoader(message?: string) {
    window.localStorage.setItem('canBack', JSON.stringify(false));
    let defaultMessage: string = 'Please Wait';
    return await this.loadingController.create({
      animated: true,
      cssClass: 'custom-loader',
      message: message ? message : defaultMessage,
      mode: 'ios',
      spinner: null,
    });
  }

  getUTCDateMiliseconds(newDate?: Date) {
    var date = new Date();
    if (newDate !== undefined) {
      date = new Date(newDate.getTime());
    }

    var now_utc = Date.UTC(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds()
    );
    return new Date(now_utc).getTime();
  }

  getUTCDateTime(newDate?: Date) {
    var date = new Date();
    if (newDate !== undefined) {
      date = new Date(newDate.getTime());
    }

    var now_utc = Date.UTC(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds()
    );
    return new Date(now_utc);
  }

  getDatePickerFormat(date: any) {
    if (date) {
      //Chepi Date
      date = new Date(date);
      return `${date.getUTCFullYear()}-${
        date.getUTCMonth() + 1 < 10
          ? '0' + (date.getUTCMonth() + 1)
          : date.getUTCMonth() + 1
      }-${date.getUTCDate() > 9 ? date.getUTCDate() : '0' + date.getUTCDate()}`;
    } else {
      return null;
    }
  }
}
