import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { FileUploader } from 'ng2-file-upload';
import { Toaster } from '../../constants/enums';
import { CreateBanner } from '../../models/aadBanner';
import { FirebaseService } from '../../services/firebase_service';
import { HttpService } from '../../services/http.service';
import { RoutingService } from '../../services/routing.service';
import { UserInterfaceService } from '../../services/ui.service';
import { UtilService } from '../../services/util.service';

@Component({
  selector: 'app-add-banner',
  templateUrl: './add-banner.component.html',
  styleUrls: ['./add-banner.component.scss'],
})
export class AddBannerComponent implements OnInit {
  @ViewChild('Form', { static: true }) Form;
  banner: CreateBanner = new CreateBanner();
  decisionFlow:any;
  uploader: FileUploader = new FileUploader({});

  constructor(
    public modalCtrl: ModalController,
    public routing: RoutingService,
    private httpService: HttpService,
    public util: UtilService,
    public ui: UserInterfaceService,
    private firebase: FirebaseService,
  ) {
  }

  ngOnInit() {
    // this.setMinDate();
  }

  async onFileChanged() {
    try {
      for (let item of this.uploader.queue) {
        let file: any = item._file;
        let res: any = await this.firebase.upload(file);
        this.banner.media.url = res.url;
      }
      this.uploader.queue = [];
    } catch (e) {
      alert('Error Uploading File');
    }
  }

  async submitForm(form: NgForm) {
    if (form.valid && this.banner.media.url) {
      await this.createBusiness();
    } else {
      this.ui.showToaster('Alert!', 'Invalid Data Entries', Toaster.error);
    }
  }

  async createBusiness() {
    let loader = await this.ui.showLoader();
    loader.present();
    try {
      let res: any = await this.httpService.createBanner(this.banner).toPromise();
      if (res && res.isSuccess) {
        this.ui.showToaster('Success!', 'Added Succesfully!');
        console.log(this.banner);
        this.modalCtrl.dismiss();
      } else {
        this.ui.showToaster('Alert!', res.message, Toaster.error);
      }
      loader.dismiss();
    } catch (e) {
      this.ui.showToaster('Alert!', e.message, Toaster.error);
      console.log(e);
      loader.dismiss();
    }
    loader.dismiss();
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

}
