/* eslint-disable @typescript-eslint/no-shadow */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// import { ConfigDTO } from 'admin/lib/model/config.dtos';
import {
  apiHost,
  ApiPrefix,
  MongoCollection,
  MongoDatabase,
} from '../constants/enums';
import { ConfigDTO } from 'backup package/lib/model/config.dtos';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  baseUrl: any;
  constructor(private http: HttpClient) {
    // this.baseUrl = ApiPrefix;
    this.baseUrl = apiHost;
  }

  auth() {
    return this.http.get(`${this.baseUrl}/auth`);
  }

  login(user: any,) {
    return this.http.post(`${this.baseUrl}/auth/adminLogin`,
      user);
  }

  getUserListing() {

    return this.http.get(`${this.baseUrl}/user/getallusers`);
  }

  getCategoryListing() {
    return this.http.get(`${this.baseUrl}/category`);
  }

  createCategory(data: any) {
    return this.http.post(`${this.baseUrl}/category/`, data);
  }




  createUser(data: any) {
    return this.http.post(`${this.baseUrl}/user/`, data);
  }

  updateUser(id: string, data: any) {
    return this.http.patch(`${this.baseUrl}/user/${id}`, data);
  }

  updatefilecomplaint(id: string, data: any) {
    return this.http.patch(`${this.baseUrl}/file-complaint/${id}`, data);
  }

  updateCashout(id: string, data: any) {
    return this.http.patch(`${this.baseUrl}/cashout-request/${id}`, data);
  }

  updateFeature(data: any) {
    return this.http.post(`${this.baseUrl}/user/feature`, data);
  }

  getUser(id: string) {
    return this.http.get(`${this.baseUrl}/user/${id}`);
  }

  getComplaint(id: string) {
    return this.http.get(`${this.baseUrl}/file-complaint/${id}`);
  }

  changePassword(data: any) {
    return this.http.post(`${this.baseUrl}/user/changepassword/`, data);
  }

  getMenuItems(name: string) {
    return this.http.get(`${this.baseUrl}/config?name=${name}`);
  }

  createConfig(data: ConfigDTO) {
    return this.http.post(`${this.baseUrl}/config`, data);
  }

  updateConfig(id: string, data: ConfigDTO) {
    return this.http.patch(`${this.baseUrl}/config/${id}`, data);
  }

  getRoleSettings(roleId: string) {
    return this.http.get(`${this.baseUrl}/role/${roleId}`);
  }

  getAllRoleSettings() {
    return this.http.get(`${this.baseUrl}/role/`);
  }

  updateTenants(id: string, data: any) {
    return this.http.patch(`${this.baseUrl}/tenants/${id}`, data);
  }

  getTenants(id: string) {
    return this.http.get(`${this.baseUrl}/tenants/${id}`);
  }

  getTenantsAll() {
    return this.http.get(`${this.baseUrl}/tenants/withoutQuery`);
  }

  getTenantByUserId(id: string) {
    return this.http.get(`${this.baseUrl}/tenants/getByUserId/${id}`);
  }

  bannedTenants(id: string, ban: string) {
    return this.http.post(`${this.baseUrl}/tenants/${id}/${ban}`, {});
  }

  search(
    apiHost: string,
    database: MongoDatabase,
    collection: MongoCollection,
    query: string = ''
  ) {
    return this.http.get(
      `${apiHost}/admin/api/v1/search/${database}/${collection}${query}`
    );
  }

  getSlots() {
    return this.http.get(`${this.baseUrl}/order/slots`);
  }

  getOrderBySlot(selectedSlot: any, startFrom: any, endTo: any) {
    return this.http.get(
      `${this.baseUrl}/order/orderBySlots?slot=${selectedSlot}&startFrom=${startFrom}&endTo=${endTo}`
    );
  }

  getFeatureById(id: string) {
    return this.http.get(`${this.baseUrl}/featured/${id}`);
  }

  addFeatureItem(data: any) {
    return this.http.post(`${this.baseUrl}/featured`, data);
  }

  updateFeatureItem(id: string, data: any) {
    return this.http.patch(`${this.baseUrl}/featured/${id}`, data);
  }

  deleteFeaturedItem(id: string) {
    return this.http.delete(`${this.baseUrl}/featured/${id}`);
  }

  getActiveProducts() {
    return this.http.get(`${this.baseUrl}/featured/getProducts`);
  }

  getLink(data: any) {
    return this.http.post(`${this.baseUrl}/links`, data);
  }
  getAllLink() {
    return this.http.get(`${this.baseUrl}/links`);
  }
  deleteLink(id: '') {
    return this.http.delete(`${this.baseUrl}/links/${id}`);
  }
  updateLink(id: '', data: any) {
    return this.http.patch(`${this.baseUrl}/links/${id}`, data);
  }
  getIdLink(id: '',) {
    return this.http.get(`${this.baseUrl}/links/${id}`);
  }

  getDashboardDetails() {
    return this.http.get(`${this.baseUrl}/dashboard/detail`);
  }

  getBusiness(id: string) {
    return this.http.get(`${this.baseUrl}/business/${id}`);
  }

  getOnboarding(id: string) {
    return this.http.get(`${this.baseUrl}/onboarding/${id}`);
  }

  createPromo(data: any) {
    return this.http.post(`${this.baseUrl}/promo-code/`, data);
  }

  getPromobyId(id: string) {
    return this.http.get(`${this.baseUrl}/promo-code/${id}`);
  }

  getBookingbyId(id: string) {
    return this.http.get(`${this.baseUrl}/bookings/${id}`);
  }

  getCashRequestbyId(id: string) {
    return this.http.get(`${this.baseUrl}/cashout-request/${id}`);
  }

  getBidbyId(id: string) {
    return this.http.get(`${this.baseUrl}/bids/${id}`);
  }

  createOnboarding(data: any) {
    return this.http.post(`${this.baseUrl}/onboarding/`, data);
  }
  createBanner(data: any) {
    return this.http.post(`${this.baseUrl}/banner/`, data);
  }

  getUserDetail(id:string){
    return this.http.get(`${this.baseUrl}/user/${id}`)
  }
}


