import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AdminModule } from 'admin';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ApiModule } from './interceptors/api.module';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { Broadcaster } from './providers/broadcaster';
import { HttpService } from './shared/services/http.service';
import { StorageService } from './shared/services/storage.service';
import { SharedModule } from './shared/shared.module';
// import { MatDialogModule } from '@angular/material/dialog';
@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    // MatDialogModule,
    BrowserModule,
    ReactiveFormsModule,
    AppRoutingModule,
    CommonModule,
    FormsModule,
    PerfectScrollbarModule,
    SharedModule,
    ApiModule.forRoot('/admin'),
    IonicModule.forRoot(),
    HttpClientModule,
  ],
  providers: [
    HttpService,
    StorageService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    Broadcaster,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
