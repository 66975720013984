import { Component, OnInit } from '@angular/core';
import { SuperAdminPaths } from './helpers/enum';
import { FirebaseService } from './shared/services/firebase_service';
import { HttpService } from './shared/services/http.service';
import { RoutingService } from './shared/services/routing.service';
import { StorageService } from './shared/services/storage.service';
import { UserInterfaceService } from './shared/services/ui.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(
    public ui: UserInterfaceService,
    public storage: StorageService,
    private routing: RoutingService,
    private firebase: FirebaseService,
    private http: HttpService,
  ) {
    if (storage.token && !window.location.pathname.includes("super-admin")) {
      this.routing.route(SuperAdminPaths.userListing);
    }
  }

  async ngOnInit() {
    await this.firebase.initialize();
  }

  async getActiveProducts() {
    try {
      let res: any = await this.http.getActiveProducts().toPromise();
      if (res && res.isSuccess) {
        this.storage.products = res.data;
      }
    } catch (e) {
      console.log(e);
    }
  }


  async getAllRoles() {
    let res: any = await this.http.getAllRoleSettings().toPromise();
    if (res && res.isSuccess) {
      this.storage.setProperty("roles", res.data);
    }
  }

}
