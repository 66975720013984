import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Toaster } from '../../constants/enums';

@Component({
  selector: 'app-toaster',
  templateUrl: './toaster.component.html',
  styleUrls: ['./toaster.component.scss'],
})
export class ToasterComponent implements OnInit {
  public title: string ="";
  public message: string = "";
  public type: Toaster = Toaster.success;
  
  constructor(
    public modalCtrl : ModalController
  ) { }

  ngOnInit() {
    console.log(this.message,this.title,this.type);
  }

  dismiss(){
    this.modalCtrl.dismiss();
  }

}
