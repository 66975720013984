import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Toaster } from '../../constants/enums';
import { CreatePromoCodeDto } from '../../models/insertPromodto';
import { FirebaseService } from '../../services/firebase_service';
import { HttpService } from '../../services/http.service';
import { RoutingService } from '../../services/routing.service';
import { UserInterfaceService } from '../../services/ui.service';
import { UtilService } from '../../services/util.service';

@Component({
  selector: 'app-add-promo-code',
  templateUrl: './add-promo-code.component.html',
  styleUrls: ['./add-promo-code.component.scss'],
})
export class AddPromoCodeComponent implements OnInit {
  @ViewChild('Form', { static: true }) Form;
  promo: CreatePromoCodeDto = new CreatePromoCodeDto();
  decisionFlow:any;

  constructor(
    public modalCtrl: ModalController,
    public routing: RoutingService,
    private httpService: HttpService,
    public util: UtilService,
    public ui: UserInterfaceService,
    private firebase: FirebaseService,
  ) {
  }

  ngOnInit() {
    this.setMinDate();
  }

  async submitForm(form: NgForm) {
    if (form.valid) {
      await this.createPromo();
    } else {
      this.ui.showToaster('Alert!', 'Invalid Data Entries', Toaster.error);
    }
  }

  async createPromo() {
    let loader = await this.ui.showLoader();
    loader.present();
    try {
      if(this.decisionFlow === 'percentage'){
        this.promo.isPercentage = true;
      }else {
        this.promo.isPercentage = false;
      }
      debugger;
      let res: any = await this.httpService.createPromo(this.promo).toPromise();
      if (res && res.isSuccess) {
        this.ui.showToaster('Success!', 'Added Succesfully!');
        console.log(this.promo);
        this.modalCtrl.dismiss();
      } else {
        this.ui.showToaster('Alert!', res.message, Toaster.error);
      }
      loader.dismiss();
    } catch (e) {
      this.ui.showToaster('Alert!', e.message, Toaster.error);
      console.log(e);
      loader.dismiss();
    }
    loader.dismiss();
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  setMinDate() {
    let today: any = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1;
    let yyyy = today.getFullYear();

    if (dd < 10) {
      dd = '0' + dd;
    }

    if (mm < 10) {
      mm = '0' + mm;
    }
    today = yyyy + '-' + mm + '-' + dd;
    let info: any = document.getElementById('endTime');
    let infoTwo: any = document.getElementById('startTime');
    info.setAttribute('min', today);
    infoTwo.setAttribute('min', today);
  }

}
