import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { RoutingService } from '../../services/routing.service';
import { HttpService } from '../../services/http.service';
import { UtilService } from '../../services/util.service';
import { UserInterfaceService } from '../../services/ui.service';
import { FirebaseService } from '../../services/firebase_service';
import { ModalController } from '@ionic/angular';
// import { ModalController } from '@ionic/angular/providers/modal-controller';

@Component({
  selector: 'app-header-navigation',
  templateUrl: './header-navigation.component.html',
  styleUrls: ['./header-navigation.component.css']
})
export class HeaderNavigationComponent implements OnInit {
  @Output() toggleSidebar = new EventEmitter<void>();
  @Input() entity: any = "account";
  @Input() keyword: any = "";

  public showSearch = false;
  user: any;
  id: any;

  constructor(
    public modalCtrl: ModalController,
    public routing: RoutingService,
    private httpService: HttpService,
    public util: UtilService,
    public ui: UserInterfaceService,
    private firebase: FirebaseService,
  ) { }

  ngOnInit(): void {
   
    // Retrieve user from local storage
    let storedUser = localStorage.getItem('user'); // assuming user data is stored as a JSON string
    if (storedUser) {
       this.user = JSON.parse(storedUser);
      this.id = this.user?._id; // Get user ID from the stored data
      if (this.id) {
        this.getUserInfo(); // Fetch user details using the ID
      }
    }
  }

  async getUserInfo() {
    try {
    
      const res: any = await lastValueFrom(this.httpService.getUserDetail(this.id));
      if (res && res.isSuccess) {
        this.user = res.data;  // Ensure that `res.data` contains `fullName`
      }
    } catch (error) {
      console.error('Error during submission:', error);
      this.ui.showToaster('Alert!', error.message);
    }
  }
  
}
