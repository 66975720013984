import { Injectable } from '@angular/core';
import { initializeApp } from 'firebase/app';
import { getStorage, ref, uploadBytes, getDownloadURL, uploadBytesResumable } from 'firebase/storage';
import { MediaModel } from 'src/app/shared/models/media.model';  // Assuming the model is defined elsewhere

@Injectable({
  providedIn: 'root',
})
export class FirebaseService {
  firebaseConfig = {
    apiKey: "AIzaSyC9RZYDaDULK33grD8N2hUftTealxeig24",
    authDomain: "bidkarlo-62ad9.firebaseapp.com",
    databaseURL: "https://bidkarlo-62ad9-default-rtdb.firebaseio.com/",
    projectId: "bidkarlo-62ad9",
    storageBucket: "bidkarlo-62ad9.appspot.com",
    messagingSenderId: "1068805920047",
    appId: "1:1068805920047:web:207aa95e7446f6635f3dda",
    measurementId: "G-E2PE6H9L1S"
  };

  constructor() {}

  // Initialize Firebase app
  async initialize() {
    initializeApp(this.firebaseConfig);
  }

  // Upload document function
  async upload(file: any) {
    return new Promise((resolve, reject) => {
      try {
        const name = new Date().getTime().toString() + "_" + file.name;
        const storage = getStorage();
        const storageRef = ref(storage, name);
        const metadata = {
          contentType: file.type,
        };
  
        const uploadTask = uploadBytesResumable(storageRef, file, metadata);
  
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            // Progress tracking (optional)
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log("Upload is " + progress + "% done");
          },
          (error) => {
            // Handle upload errors
            console.error("Error uploading file:", error);
            reject(error);
          },
          async () => {
            // Get download URL after successful upload
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            resolve({ url: downloadURL });
          }
        );
      } catch (error) {
        console.error("Unexpected error during upload:", error);
        reject(error);
      }
    });
  }
}
