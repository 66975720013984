/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/quotes */
export enum SuperAdminPaths {
  userListing = "/super-admin/users/list/User",
  addUser = "/super-admin/users/add",
  detailUser = "/super-admin/users/detail",
  detailBusiness = "/super-admin/business-detail",  
  detailPromo = "/super-admin/promo-detail",
  detailBooking = "/super-admin/booking-detail",
  detailBid = "/super-admin/bid-detail",
  createUserForm = "/super-admin/users/create-form",
  rolesListing = "/super-admin/roles/list",
  addRole = "/super-admin/roles/add",
  menu = "/super-admin/menu",
  product = "/super-admin/product",
  faq = "/super-admin/faq",
  CashoutRequest = "/super-admin/cashout-request-detail",
  filecomplaintdetail = "/super-admin/file-complaint-detail"
}
