/* eslint-disable id-blacklist */
/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { FileUploader } from 'ng2-file-upload';
import { Toaster } from '../../constants/enums';
import { CreateUserDTO } from '../../models/create-user.dto';
import { FirebaseService } from '../../services/firebase_service';
import { HttpService } from '../../services/http.service';
import { RoutingService } from '../../services/routing.service';
import { StorageService } from '../../services/storage.service';
import { UserInterfaceService } from '../../services/ui.service';
import { UtilService } from '../../services/util.service';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss'],
})
export class AddUserComponent implements OnInit {
  @ViewChild('Form', { static: true }) Form: any;
  user: CreateUserDTO = new CreateUserDTO();
  role: any;
  tempDelivery: any;
  tempCategoryJson: any;
  noOfEmployees: any;
  uploader: FileUploader = new FileUploader({});

  constructor(
    public modalCtrl: ModalController,
    public routing: RoutingService,
    private httpService: HttpService,
    public util: UtilService,
    public ui: UserInterfaceService,
    private firebase: FirebaseService,
  ) {
    this.tempCategoryJson = [
      { name: 'Dine in', id: '1' },
      { name: 'Pick Up', id: '2' },
    ];
  }

  ngOnInit() {
    if(this.role.title === 'Employer'){
      this.role.title = 'Vendor';
    }
    console.log(this.role, 'role');
    this.noOfEmployees = [
      {
        title: '5000+',
      },
      {
        title: '1000-4999',
      },
      {
        title: '500-999',
      },
      {
        title: '250-499',
      },
      {
        title: '100-249',
      },
      {
        title: '50-99',
      },
      {
        title: '10-49',
      },
      {
        title: '1-9',
      },
    ];
  }

  async submitForm(form: NgForm) {
    if (form.valid) {
      await this.addUser();
    } else {
      this.ui.showToaster('Alert!', 'Invalid Data Entries', Toaster.error);
    }
  }

  autogeneratedPassword(){
    this.user.password = Math.random().toString(36).substring(2,13);
  }

  async addUser() {
    const loader = await this.ui.showLoader();
    loader.present();
    try {
      this.user.userType = this.role.title.toLowerCase();
      this.user.address.value.location.coordinates =
        this.user.address.value.location.coordinates.map((x) => {
          x = JSON.parse(x);
          return x;
        });
      // this.user.password = '123456';
      // this.user.isOTP = true;
      this.user.phoneNumber.dialCode = '+92';
      this.user.phoneNumber.number = this.user.phoneNumber.number.trim();
      if (this.user.userType === 'vendor') {
        this.user.userType = 'employ';
      }
      if (this.user.userType === 'user') {
        this.user.userType = 'consumer';
      }
      const res: any = await this.httpService.createUser(this.user).toPromise();
      if (res && res.isSuccess) {
        this.ui.showToaster('Success!', 'Added Succesfully!');
        console.log(this.user);
        this.modalCtrl.dismiss();
      } else {
        this.ui.showToaster('Alert!', res.message, Toaster.error);
      }
      loader.dismiss();
    } catch (e) {
      this.ui.showToaster('Alert!', e.message, Toaster.error);
      console.log(e);
      loader.dismiss();
    }
    loader.dismiss();
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  setCategoryDetail(data: any) {
    this.user.companySize = data;
  }

  getCategory() {
    return [
      { name: 'Fast Food' },
      { name: 'Chinese' },
      { name: 'Italian' },
      { name: 'Indian' },
      { name: 'Turkish' },
      { name: 'Soup' },
    ];
  }

  setDeliveryType(data: any) {
    this.user.deliveryType.push(data.name);
  }

  deleteDeliveryType(ind: any) {
    this.user.deliveryType.splice(ind, 1);
  }

  async onFileChanged() {
    const loader = await this.util.showLoader('Uploading File');
    loader.present();
    try {
      for (const item of this.uploader.queue) {
        const file: any = item.file;
        if (file.type.includes('image')) {
          if (file) {
            const img = new Image();
            img.onload = (async (x: any)=>{
              const res: any = await this.firebase.upload(file);
              this.user.companyDocuments.push(res.url);
              console.log(this.user.companyDocuments);
              loader.dismiss();
            });
            img.onerror = ((e) => {
              this.util.showToast(e.toString(),Toaster.error);
              loader.dismiss();
            });
            img.src = URL.createObjectURL(file);
          }
        }else{
          this.util.showToast('Error! Unsupported File');
          loader.dismiss();
        }
      }
    }
    catch (e) {
      loader.dismiss();
    }
    if(!this.uploader.queue.length){
      loader.dismiss();
    }
    this.uploader.queue = [];
  }

}
