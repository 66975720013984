import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Toaster } from '../../constants/enums';
import { createCategory } from '../../models/addBusiness';
import { FirebaseService } from '../../services/firebase_service';
import { HttpService } from '../../services/http.service';
import { RoutingService } from '../../services/routing.service';
import { UserInterfaceService } from '../../services/ui.service';
import { UtilService } from '../../services/util.service';
import { FileUploader } from 'ng2-file-upload';
import { Media } from '../../models/media';

@Component({
  selector: 'app-add-business',
  templateUrl: './add-business.component.html',
  styleUrls: ['./add-business.component.scss'],
})
export class AddBusinessComponent implements OnInit {
  @ViewChild('Form', { static: true }) Form;
  @ViewChild('fileInput', { static: false }) fileInput!: ElementRef<HTMLInputElement>;

  promo: createCategory = new createCategory();
  uploader: FileUploader = new FileUploader({});
  isUploading: boolean = false;
  addCategory: any = { media: [] }; // Initialize media as an empty array

  // Separate fields for phone number and dial code
  dialCode: string = '';
  phoneNumber: string = '';

  constructor(
    public modalCtrl: ModalController,
    public routing: RoutingService,
    private httpService: HttpService,
    public util: UtilService,
    public ui: UserInterfaceService,
    private firebase: FirebaseService,
  ) {}

  ngOnInit() {
    // Ensure promo object is initialized
  }

  async submitForm(form: NgForm) {
    if (form.valid && this.addCategory.media.url) {
      try {
      
        const dialCode = form.value.dialCode;
        const phoneNumber = form.value.phoneNumber;
        const email = `${dialCode}${phoneNumber}@example.com`; // Generating email from phone (for Firebase login)
        const password = '123456'; // Set a default password or handle password input

        // Set phone number and dial code in promo object
        this.promo.phoneNumber = phoneNumber;
        this.promo.dialCode = dialCode;
        this.promo.photoUrl= this.addCategory.media.url
        // Call createCategory
        await this.createCategory();
      } catch (error) {
        console.error('Error during submission:', error);
        this.ui.showToaster('Alert!', error.message, Toaster.error);
      }
    } else {
      this.ui.showToaster('Alert!', 'Invalid Data Entries', Toaster.error);
    }
  }

  async createCategory() {
    try {
     
      let res: any = await this.httpService.createCategory(this.promo).toPromise();
      if (res && res.isSuccess) {
        this.ui.showToaster('Success!', 'Added Successfully!');
        this.modalCtrl.dismiss();
      } else {
        this.ui.showToaster('Alert!', res.message, Toaster.error);
      }
    } catch (e) {
      this.ui.showToaster('Alert!', e.message, Toaster.error);
    }
  }

  // Method to handle file changes (uploads)
  async onFileChangedGallery() {
    try {
      for (let item of this.uploader.queue) {
        let file: any = item._file;
        let res: any = await this.firebase.upload(file);
        this.addCategory.media.url = res.url;
      }
      this.uploader.queue = [];
    } catch (e) {
      alert('Error Uploading File');
    }
  }
  

  browseFiles() {
    this.fileInput.nativeElement.click();
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }
}
