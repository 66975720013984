import { Injectable, HostListener } from '@angular/core';
import { AlertController, ModalController, LoadingController } from '@ionic/angular';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { AlertPopupComponent } from '../components/alert-popup/alert-popup.component';
import { ToasterComponent } from '../components/toaster/toaster.component';
import { Toaster } from '../constants/enums';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: "root"
})
export class UserInterfaceService {
  public config: PerfectScrollbarConfigInterface = {};
  public innerWidth: any;
  public uiVariables: any;
  constructor(
    private storage: StorageService,
    private alertCtrl: AlertController,
    public loadingCtrl: LoadingController,
    private modalCtrl: ModalController,
  ) {
    this.uiVariables = this.storage.getProperty('uiVariables');
    if (!this.uiVariables) {
      this.uiVariables = {
        "defaultSidebar": "mini-sidebar",
        "showMobileMenu": false,
        "expandLogo": false,
        "sidebartype": "mini-sidebar",
      };
    }
  }

  saveVariable() {
    this.storage.setProperty('uiVariables', this.uiVariables);
  }

  Logo() {
    this.uiVariables.expandLogo = !this.uiVariables.expandLogo;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.handleSidebar();
  }

  handleSidebar() {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 1170) {
      this.uiVariables.sidebartype = 'mini-sidebar';
      this.uiVariables.expandLogo = false;
    } else {
      this.uiVariables.sidebartype = this.uiVariables.sidebartype;
    }
    this.saveVariable();
  }

  toggleSidebarType() {
    switch (this.uiVariables.sidebartype) {
      case 'full':
        this.uiVariables.sidebartype = 'mini-sidebar';
        this.uiVariables.expandLogo = false;
        break;
      case 'mini-sidebar':
        this.uiVariables.sidebartype = 'full';
        this.uiVariables.expandLogo = true;
        break;
      default:
        this.uiVariables.sidebartype = this.uiVariables.defaultSidebar;
        if (this.uiVariables.sidebartype === 'full') {
          this.uiVariables.expandLogo = true;
        } else {
          this.uiVariables.expandLogo = false;
        }
    }
    this.saveVariable();
  }

  async showToaster(title?: string, message?: string, type: Toaster = Toaster.success) {
    let props = {
      title: title,
      message: message,
      type: type,
    }
    const modal = await this.modalCtrl.create({
      component: ToasterComponent,
      cssClass: 'toaster',
      backdropDismiss: false,
      showBackdrop: false,
      componentProps: props && props,
    });
    await modal.present();
    setTimeout(() => {
      modal.dismiss();
    }, 2000);
  }

  async showAlert(title?: string, message?: string, type?: string, reload: boolean = false, recievedObj?: any) {
    let props = {
      title: title,
      message: message,
      type: type,
      reload: reload,
      recievedObj: recievedObj
    }
    const modal = await this.modalCtrl.create({
      component: AlertPopupComponent,
      cssClass: 'alertComponent',
      backdropDismiss: false,
      showBackdrop: true,
      componentProps: props && props,
    });
    await modal.present();
  }

  async showLoader(message?: string) {
    return await this.loadingCtrl.create({
      spinner: 'bubbles',
      message: message ? message : 'Please Wait!',
      mode: "ios",
      translucent: true,
    });
  }


  setFocusOnEllipsis(id: any) {
    let elem: any = document.getElementById(id);
    if (elem) {
      elem.classList.add('focus');
      setTimeout(() => {
        elem.classList.remove('focus');
      }, 2000);
    }
  }

  setFocus(elem: any) {
    elem.classList.add('focus');
  }

  removeFocus(elem: any) {
    setTimeout(() => {
      elem.classList.remove('focus')
    }, 200);
  }

  async showConfirmDialog() {
    return new Promise(async (resolve, reject) => {
      const alert = await this.alertCtrl.create({
        header: 'Confirm!',
        message: 'Are you Sure?',
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary',
            handler: (blah) => {
              reject(false);
            }
          }, {
            text: 'Okay',
            handler: () => {
              resolve(true);
            }
          }
        ]
      });
      await alert.present();
    });
  }

}
