export enum AdminPath {
    managersList = "admin/managers/list",
    managerDetail = "admin/managers/detail",
    agentsList = "admin/agents/list",
    agentsDetail = "admin/agents/detail",
    contactsList = "admin/contacts/list",
    contactsDetail = "admin/contacts/detail",
    usersList = "admin/users/list",
    addUser = "admin/users/add",
    faqs = "admin/faqs-creater",
    privacyPolicy = "admin/privacy-policy-creater",
    filecomplaintdetail = "admin/file-complaint-detail",
}

export enum Toaster {
    success = "success",
    error = "error",
    warning = "warning",
}

export enum StorageKeys {
    canBack = "canBack",
    user = "user",
    token = "token",
    tenant = "tenant",
    roles = "roles",
    currentSystemMenuItems = "currentSystemMenuItems"
}

export enum Path {
    login = "login",
    link = "super-admin/link",
    changePassword = "change-password",
    forgetPassword = "forget-password",
}

export enum SystemTypes {
    SuperAdmin,
    Admin,
}

export enum EventEmitterTypes {
    FormSubmit,
    FaqFormSubmit,
    PrivacyPolicyFormSubmit
}

export enum ScreenFetaures {
    Add = "Add",
    Edit = "Edit",
    Delete = "Delete",
    UpdateStatus = "UpdateStatus",
}


export enum MongoDatabase {
    tenantConfig = "FrontLiners",
    tenants = "FrontLiners"
}

export enum MongoCollection {
    configs = "configs",
    logs = "logs",
    roles = "roles",
    tenants = "tenants",
    users = "users",
    agents = "agents",
    media = "media",
    menu = "menu",
    products = "products",
    rawFiles = "raw_files",
    categories = "categories",
}

export enum StaticConfig {
    PrivacyPolicy = "PrivacyPolicy",
    Faqs = "Contact_Us",
    TermsAndConditions = "TermsAndConditions"
}

export const ToolsGlobalConstants = {
    ApiVersion: "v1",
}

const ApiVersion = "v1";
export const ApiPrefix = `/admin/api/${ApiVersion}`;
// export const apiHost = `${window.location.protocol + "//" + window.location.host}${ApiPrefix}`
// export const apiHost = `http://localhost:9001/admin/api/v1`
export const apiHost = `https://bidkarlo.com/admin/api/v1`;

  