import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UtilService } from '../../services/util.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit {
  @Input() buttonText: string = "";
  @Input() title: string = "";
  @Output() emitter = new EventEmitter<void>();

  constructor(
    private util : UtilService,
  ) { }

  ngOnInit() { }

}
