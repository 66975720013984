import { Injectable } from '@angular/core';
import { RoutingService } from './routing.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(
    private storage: StorageService,
    private routing: RoutingService,
  ) { }

  signOut(){
    this.storage.removeAllProperties();
    this.routing.route("/login",true);
  }




}
