import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, PopoverController } from '@ionic/angular';
import { StorageService } from './storage.service';
import { Location } from '@angular/common';
import { Path, StorageKeys } from '../constants/enums';
import { AddUserComponent } from '../components/add-user/add-user.component';
// import { Role } from 'superadmin';
import { AddPromoCodeComponent } from '../components/add-promo-code/add-promo-code.component';
import { AddBusinessComponent } from '../components/add-business/add-business.component';
import { AddOnboardingComponent } from '../components/add-onboarding/add-onboarding.component';
import { AddBannerComponent } from '../components/add-banner/add-banner.component';

@Injectable({
  providedIn: 'root'
})
export class RoutingService {

  constructor(
    private router: Router,
    private _location: Location,
    private modalCtrl: ModalController,
    private popCtrl: PopoverController,
    private storage: StorageService
  ) { }

  redirect() {
    // this.storage.RemoveProperty(StorageKeys.RedirectUrl);
    console.log('Routing Service');
  }

  goBack() {
    // eslint-disable-next-line no-underscore-dangle
    this._location.back();
  }

  dynamicPath(path: string, params: string = '') {
    if (params) {
      this.route(path, false, { id: params });
    } else {
      this.route(path);
    }
  }

  goToLogin() {
    this.route(Path.login);
  }
  goToLink() {
    this.route(Path.link);
  }

  showInfo(type, id) {
    this.route(`/super-admin/users/info/${type}/${id}`);
  }

  showProducts(id) {
    this.route(`/super-admin/users/products/${id}`);
  }

  showMenu(id) {
    this.route(`/super-admin/users/menu/${id}`);
  }

  showMedia(userId: string = null, tenantId: string = null) {
    const query = {};
    if (userId) {
      query[userId] = userId;
    } else if (tenantId) {
      query[tenantId] = tenantId;
    }
    this.route(`/super-admin/users/media`, false, query);
  }

  showHours(id: string) {
    this.route(`/super-admin/users/working-hours/${id}`);
  }

  showOrders(id: string) {
    this.route(`/super-admin/users/orders-list/${id}`);
  }

  goToChangePassword() {
    this.route(Path.changePassword);
  }

  goToForgetPassword() {
    this.route(Path.forgetPassword);
  }

  route(path: string, replaceUrl: boolean = false, data?: any) {
    if (!data) {
      this.router.navigate([path], { replaceUrl });
    } else {
      this.router.navigate([path], { replaceUrl, queryParams: data });
    }
  }

  async showAddUserModel(role: any) {
    return await this.showModal(AddUserComponent, 'addmanager', { role });
  }


  async showAddPromoModel() {
    return await this.showModal(AddPromoCodeComponent, 'addmanager',);
  }

  async showAddBusinessModel() {
    return await this.showModal(AddBusinessComponent, 'addmanager',);
  }

  async showAddOnboardingModel() {
    return await this.showModal(AddOnboardingComponent, 'addmanager',);
  }

  async showAddBannerModel() {
    return await this.showModal(AddBannerComponent, 'addmanager',);
  }

  // async showVendorOrders(order: OrderVendor, data: any[]) {
  //   return await this.showModal(VendorOrdersComponent, '', { order: order, allOrders: data });
  // }

  // async showOrderDetail(order: any) {
  //   return await this.showModal(OrderDetailComponent, '', { order: order });
  // }

  // async showFeaturedModel(component, id: string = null) {
  //   return await this.showModal(component, 'addmanager', { id: id });
  // }

  // async showAddProductModel(list:any[]) {
  //   return await this.showModal(SelectProductsComponent, 'addmanager', { list: list });
  // }

  async showModal(component: any, cssClass: string, props?: any, showBackdrop: boolean = true, backdropDismiss: boolean = true) {

    const modal = await this.modalCtrl.create({
      component,
      cssClass,
      componentProps: props && props,
      showBackdrop,
      backdropDismiss
    });
    this.storage.setProperty(StorageKeys.canBack, false);
    await modal.present();
    const dismissRes = await modal.onWillDismiss();
    this.storage.setProperty(StorageKeys.canBack, true);
    return dismissRes;
  }

  async showPopover(ev: any, component: any, cssClass: string, props?: any, showBackdrop: boolean = true) {
    const popover = await this.popCtrl.create({
      component,
      cssClass,
      showBackdrop,
      mode: 'ios',
      componentProps: props && props,
      event: ev
    });
    this.storage.setProperty(StorageKeys.canBack, false);
    await popover.present();
    const dismissRes = await popover.onWillDismiss();
    this.storage.setProperty(StorageKeys.canBack, true);
    return dismissRes;
  }

}
