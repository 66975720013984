import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  {
    path: 'super-admin',
    loadChildren: () => import('./private/private.module').then(m => m.PrivateModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('./auth/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  // {
  //   path: 'privacy-policy',
  //   loadChildren: () => import('./privacy-policy/privacy-policy.module').then( m => m.PrivacyPolicyPageModule)
  // },

];


// , {
      //   path: 'user-list',
      //   loadChildren: () => import('./private/user-list/user-list.module').then(m => m.UserListPageModule)
      // },
      // {
      //   path: 'add-user',
      //   loadChildren: () => import('./private/add-user/add-user.module').then(m => m.AddUserPageModule)
      // },
      // {
      //   path: 'create-user-form',
      //   loadChildren: () => import('./private/create-user-form/create-user-form.module').then(m => m.CreateUserFormPageModule)
      // },

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
