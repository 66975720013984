import { Injectable } from '@angular/core';
import { initializeApp } from 'firebase/app';
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';

@Injectable({
  providedIn: 'root',
})
export class FirebaseService {
  firebaseConfig = {
    apiKey: "AIzaSyC9RZYDaDULK33grD8N2hUftTealxeig24",
    authDomain: "bidkarlo-62ad9.firebaseapp.com",
    databaseURL: "https://bidkarlo-62ad9-default-rtdb.firebaseio.com/",
    projectId: "bidkarlo-62ad9",
    storageBucket: "bidkarlo-62ad9.appspot.com",
    messagingSenderId: "1068805920047",
    appId: "1:1068805920047:web:207aa95e7446f6635f3dda",
    measurementId: "G-E2PE6H9L1S"
  };

  constructor() {}

  async initialize() {
    await initializeApp(this.firebaseConfig);
  }

  async upload(file: any) {
    return new Promise(async (resolve, reject) => {
      const name = new Date().getTime().toString() + file.name;
      const storage = getStorage();
      const storageRef = ref(storage, name);
      const metadata = {
        contentType: file.type,
      };
      uploadBytes(storageRef, file, metadata).then(async (res) => {
        var obj: any = {
          file: {
            mimetype: file.type,
            originalname: file.name,
            fileName: name,
            size: file.size,
          },
        };
        obj.url = await getDownloadURL(res.ref);
        resolve(obj);
      });
    });
  }
}
