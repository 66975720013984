import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Toaster } from '../../constants/enums';
import { createCategory } from '../../models/addBusiness';
import { FirebaseService } from '../../services/firebase_service';
import { HttpService } from '../../services/http.service';
import { RoutingService } from '../../services/routing.service';
import { UserInterfaceService } from '../../services/ui.service';
import { UtilService } from '../../services/util.service';
import { FileUploader } from 'ng2-file-upload';
import { MediaModel } from '../../models/media';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, getAuth } from 'firebase/auth';

@Component({
  selector: 'app-add-business',
  templateUrl: './add-business.component.html',
  styleUrls: ['./add-business.component.scss'],
})
export class AddBusinessComponent implements OnInit {
  @ViewChild('Form', { static: true }) Form;
  @ViewChild('fileInput', { static: false }) fileInput!: ElementRef<HTMLInputElement>;

  promo: createCategory = new createCategory();
  uploader: FileUploader = new FileUploader({});
  isUploading: boolean = false;

  // Separate fields for phone number and dial code
  dialCode: string = '';
  phoneNumber: string = '';

  constructor(
    public modalCtrl: ModalController,
    public routing: RoutingService,
    private httpService: HttpService,
    public util: UtilService,
    public ui: UserInterfaceService,
    private firebase: FirebaseService,
  ) {}

  ngOnInit() {
    // Ensure promo object is initialized
  }

  // async firebaseLogin(email: string, password: string) {
  //   const auth = getAuth();
  //   try {
  //     debugger
  //     // Attempt to sign in the user
  //     const userCredential = await signInWithEmailAndPassword(auth, email, password);
  //     return userCredential.user;
  //   } catch (error) {
  //     if (error.code === 'auth/user-not-found') {
  //       // If user is not found, create the user
  //       const newUserCredential = await createUserWithEmailAndPassword(auth, email, password);
  //       return newUserCredential.user;
  //     } else {
  //       // Handle other errors (e.g., wrong password)
  //       throw error;
  //     }
  //   }
  // }
  async submitForm(form: NgForm) {
    if (form.valid) {
      try {
        debugger
        const dialCode = form.value.dialCode;
        const phoneNumber = form.value.phoneNumber;
        const email = `${dialCode}${phoneNumber}@example.com`; // Generating email from phone (for Firebase login)
        const password = '123456'; // Set a default password or handle password input
  
        // Firebase login or registration
        // const user = await this.firebaseLogin(email, password);
  
        // if (user) {
        //   const uid = user.uid; // Get Firebase user uid
          
        //   // Set uid, phoneNumber, and dialCode in promo object
          this.promo.phoneNumber = phoneNumber;
          this.promo.dialCode = dialCode;
          // this.promo.uid = uid; // Add uid to promo object
  
          // Call createCategory after uid is set
          await this.createCategory();
        // } else {
        //   this.ui.showToaster('Alert!', 'User not logged in', Toaster.error);
        // }
      } catch (error) {
        console.error('Error during Firebase login:', error);
        this.ui.showToaster('Alert!', error.message, Toaster.error);
      }
    } else {
      this.ui.showToaster('Alert!', 'Invalid Data Entries', Toaster.error);
    }
  } 
  
  

  async createCategory() {
    try {
      let res: any = await this.httpService.createCategory(this.promo).toPromise();
      if (res && res.isSuccess) {
        this.ui.showToaster('Success!', 'Added Successfully!');
        this.modalCtrl.dismiss();
      } else {
        this.ui.showToaster('Alert!', res.message, Toaster.error);
      }
    } catch (e) {
      this.ui.showToaster('Alert!', e.message, Toaster.error);
    }
  }

  async onFileChangedGallery(event: any) {
    try {
      const file = event.target.files[0];
      if (file) {
        this.isUploading = true;
        let img = new Image();
        img.onload = async (x: any) => {
          let res: any = await this.firebase.upload(file);
          if (res) {
            let media = new MediaModel();
            media.url = res.url;
            this.promo.photoUrl = media.url;
          }
          this.isUploading = false;
        };
        img.onerror = (e: any) => {
          this.isUploading = false;
          console.error('Image load error:', e);
        };
        img.src = URL.createObjectURL(file);
      }
    } catch (e) {
      console.error('Error:', e); // Log the error for debugging
      this.isUploading = false;
    }
  }

  browseFiles() {
    this.fileInput.nativeElement.click();
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }
}
