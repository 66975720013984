import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddUserComponent } from './components/add-user/add-user.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CommonService } from './services/common.service';
import { HttpService } from './services/http.service';
import { RoutingService } from './services/routing.service';
import { StorageService } from './services/storage.service';
import { UserInterfaceService } from './services/ui.service';
import { UtilService } from './services/util.service';
import { AlertPopupComponent } from './components/alert-popup/alert-popup.component';
import { HeaderNavigationComponent } from './components/header-navigation/header-navigation.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { SideBarComponent } from './components/side-bar/side-bar.component';
import { IonicModule } from '@ionic/angular';
import { ToasterComponent } from './components/toaster/toaster.component';
// import { AdminModule } from 'admin';
import { FileUploadModule } from 'ng2-file-upload';
import { AddPromoCodeComponent } from './components/add-promo-code/add-promo-code.component';
import { AddBusinessComponent } from './components/add-business/add-business.component';
import { AddOnboardingComponent } from './components/add-onboarding/add-onboarding.component';
import { AddBannerComponent } from './components/add-banner/add-banner.component';




@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    IonicModule.forRoot(),
    ReactiveFormsModule,
    FileUploadModule,
  ],
  declarations: [
    AddUserComponent,
    AlertPopupComponent,
    HeaderNavigationComponent,
    BreadcrumbComponent,
    ToasterComponent,
    SideBarComponent,
    AddPromoCodeComponent,
    AddBusinessComponent,
    AddOnboardingComponent,
    AddBannerComponent
  ],
  entryComponents: [
    AddUserComponent,
    ToasterComponent,
    AlertPopupComponent,
    HeaderNavigationComponent,
    BreadcrumbComponent,
    AddPromoCodeComponent,
    SideBarComponent,
    AddBusinessComponent,
    AddOnboardingComponent,
    AddBannerComponent
  ],
  exports:[
    AddUserComponent,
    AlertPopupComponent,
    HeaderNavigationComponent,
    BreadcrumbComponent,
    AddPromoCodeComponent,
    AddBusinessComponent,
    AddOnboardingComponent,
    ToasterComponent,
    SideBarComponent,
    AddBannerComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
  ],
  providers: [
    CommonService,
    HttpService,
    RoutingService,
    UserInterfaceService,
    UtilService,
  ],

})
export class SharedModule { }
