import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Toaster } from '../../constants/enums';
import { CreatePromoCodeDto } from '../../models/insertPromodto';
import { FirebaseService } from '../../services/firebase_service';
import { HttpService } from '../../services/http.service';
import { RoutingService } from '../../services/routing.service';
import { UserInterfaceService } from '../../services/ui.service';
import { UtilService } from '../../services/util.service';

@Component({
  selector: 'app-add-onboarding',
  templateUrl: './add-onboarding.component.html',
  styleUrls: ['./add-onboarding.component.scss'],
})
export class AddOnboardingComponent implements OnInit {
  @ViewChild('Form', { static: true }) Form;
  promo: any = {};
  decisionFlow:any;

  constructor(
    public modalCtrl: ModalController,
    public routing: RoutingService,
    private httpService: HttpService,
    public util: UtilService,
    public ui: UserInterfaceService,
    private firebase: FirebaseService,
  ) {
  }

  ngOnInit() {
    // this.setMinDate();
  }

  async submitForm(form: NgForm) {
    if (form.valid) {
      await this.createOnboarding();
    } else {
      this.ui.showToaster('Alert!', 'Invalid Data Entries', Toaster.error);
    }
  }

  async createOnboarding() {
    let loader = await this.ui.showLoader();
    loader.present();
    try {
    ;
      let res: any = await this.httpService.createOnboarding(this.promo).toPromise();
      if (res && res.isSuccess) {
        this.ui.showToaster('Success!', 'Added Succesfully!');
        console.log(this.promo);
        this.modalCtrl.dismiss();
      } else {
        this.ui.showToaster('Alert!', res.message, Toaster.error);
      }
      loader.dismiss();
    } catch (e) {
      this.ui.showToaster('Alert!', e.message, Toaster.error);
      console.log(e);
      loader.dismiss();
    }
    loader.dismiss();
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

}
