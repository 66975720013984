import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageKeys } from '../constants/enums';
import { UtilService } from './util.service';


@Injectable({
  providedIn: 'root'
})
export class StorageService {
  products: any[] = [];

  constructor(private util: UtilService, private router: Router, private activatedRoute: ActivatedRoute) {
    console.log("storage");
  }

  getProperty(key: any) {
    let val: any = window.localStorage.getItem(key);
    if (val != "undefined") {
      return JSON.parse(val);
    } else {
      return val;
    }
  }

  setProperty(key: any, value: any, parse = true) {
    if (parse) {
      window.localStorage.setItem(key, JSON.stringify(value));
    } else {
      window.localStorage.setItem(key, value);
    }
  }

  removeProperty(key: any) {
    window.localStorage.removeItem(key);
    return true;
  }

  removeAllProperties() {
    window.localStorage.clear();
    return true;
  }

  setToken(token: string) {
    this.setProperty(StorageKeys.token, token);
  }

  setUser(user: any) {
    delete user.password;
    this.setProperty(StorageKeys.user, user);
  }

  get token() {
    let token: any = this.getProperty(StorageKeys.token);
    if (token)
      return token;
    return null;
  }


  get user() {
    let user: any = this.getProperty(StorageKeys.user);
    if (user)
      return user;
    return null;
  }

  get RoleId() {
    let user = this.user;
    if (user && user.roleId) {
      return user.roleId;
    }
    return null;
  }

  get UserId() {
    let user = this.user;
    if (user && user._id) {
      return user._id;
    }
    return null;
  }

  get AllProducts() {
    return JSON.parse(JSON.stringify(this.products));
  }




}
