import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { CommonService } from '../shared/services/common.service';
import { StorageService } from '../shared/services/storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    public common: CommonService,
    public storage: StorageService
  ) {

  }

  async canActivate(): Promise<boolean> {
    if (!this.storage.token) {
      this.common.signOut();
      return false;
    } else {
      return true;
    }
  }


}
