export class CreateUserDTO {
  fullName: string;
  // email: string;
  phoneNumber: PhoneNumberDTO = new PhoneNumberDTO();
  dob: string;
  nationality: string;
  subdomain: string;
  userType: string;
  password: string;
  address: Address = new Address();
  category: string = '';
  companySize: string = '';
  deliveryType: string[] = [];
  startTime: any;
  companyBio: string;
  companyWebsite: string;
  endTime: any;
  companyDocuments: string[] = [];
  poBox: string;
  country: string;
  city: string;
  isOTP: boolean = true;
  isActive: boolean = true;
  isBusinessAccount:boolean = false;

}

export class AddressInfo {
  streetAddress: string = '';
  location: Location = new Location();
  city: string = '';
  state: string = '';
  country: string = '';
}

export class Address {
  type: string = '';
  description: string = '';
  value: AddressInfo = new AddressInfo();
}

export class Location {
  type: string = 'Point';
  coordinates: any[] = [];
}

export class PhoneNumberDTO {
  number: string;
  dialCode: string;
}
