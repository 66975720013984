import { Component, Input, OnInit } from '@angular/core';
import { RoutingService } from 'src/app/shared/services/routing.service';
import { StorageService } from '../../services/storage.service';

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss'],
})
export class UserDetailComponent implements OnInit {
  @Input() user: any;
// user:any;
  constructor(
    private readonly routing:RoutingService,
    private readonly storage:StorageService
  ) { }

  ngOnInit() {
//    
// this.user= this.storage.user;
if(this.user){
console.log(this.user);
}else{
  console.log('User Not Found');
}
  }



}
